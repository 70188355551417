<template>
  <vue-perfect-scrollbar
    :settings="{ suppressScrollX: true, wheelPropagation: false }"
    class="h-100 rtl-ps-none ps scroll"
    style="height: 100%"
  >
    <div class="p-5 relative">
      <v-app-bar
        :color="$vuetify.theme.dark ? 'dark' : 'white'"
        :dark="$vuetify.theme.dark"
        class="pt-1 shadow-sm"
        fixed
      >
        <div class="flex justify-between items-center">
          <h6 class="m-0">My Account</h6>
          <slot name="userDrawerCloseButton"></slot>
        </div>
      </v-app-bar>
    </div>

    <div class="p-5 mt-10">
      <div class="heading-label p-0 mb-4">Profile</div>
      <div class="flex items-center mb-10">
        <v-avatar size="56" class="mr-2">
          <img src="@/assets/images/faces/placeholder.png" alt="" />
        </v-avatar>
        <div>
          <a href="#" class="link-alt">
            <p class="font-semibold m-0">
              {{ this.$store.state.authData.userName }}
            </p>
          </a>
          <p class="text-sm text--disabled mb-2">Staff</p>
          <!-- <v-btn small icon color="">
            <v-icon small dense>mdi-google</v-icon>
          </v-btn>
          <v-btn small icon color="">
            <v-icon small dense>mdi-twitter</v-icon>
          </v-btn>
          <v-btn small icon color="">
            <v-icon small dense>mdi-facebook</v-icon>
          </v-btn> -->
        </div>
      </div>
    </div>
  </vue-perfect-scrollbar>
</template>

<script>
export default {
  name: "UserDrawer",
  props: {},
  data() {
    return {
      items: [],
    };
  },
  computed: {},
  methods: {},
};
</script>

<style scoped></style>
